import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'

import '../styles/calendar.css'

const Calendar = ({ data }) => {

  let events = data.markdownRemark.frontmatter.events.map(event => {
    event.date = new Date(event.date)
    return event
  })

  events = events.sort((a, b) => a.date - b.date)

  events = events.filter(event => moment(event.date).isAfter(moment().add(-1, 'days')))

  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        <ReactMarkdown children={data.markdownRemark.frontmatter.blurb} />
        <h2>Goals</h2>
        <ReactMarkdown children={data.markdownRemark.frontmatter.goals} />
        <h2>Events</h2>
        {events.length > 0 ? (
          <table className="calendar">
            <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
              {events.map(event => (
                <tr key={event.title + event.date.toString()}>
                  <td>{moment(event.date).format('MMMM DD, YYYY')}</td>
                  <td>{event.link ? <a href={event.link} target="_blank" rel="noreferrer">{event.title}</a> : event.title}</td>
                </tr>
              ))}
            </tbody>
          </table> 
        ) : <ReactMarkdown children={data.markdownRemark.frontmatter.noEvents} />}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query CalendarQuery {
  markdownRemark(frontmatter: {title: {eq: "Calendar"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      blurb
      goals
      noEvents
      events {
        title
        date
        link
      }
    }
  }
}
`

export default Calendar